/**
 * Created by huck on 15.06.18
 */
import { Component, Input } from "@angular/core";
import ICourse from "ecoreps_shared/model/course";

@Component({
  selector: "course-preview",
  templateUrl: "./coursepreview.component.html",
  styleUrls: ["./coursepreview.component.scss"]
})
export class CoursePreviewComponent {
  @Input() public course: ICourse;
}
