import { Injectable } from "@angular/core";
import { IChapter, PreviewMode } from "ecoreps_shared/model/chapter";
import ICourse from "ecoreps_shared/model/course";
import { LoginService } from "./login.service";

@Injectable({
  providedIn: "root"
})
export class ChapterService {
  constructor(private loginService: LoginService) {}

  public isChapterForbidden(chapter: IChapter, course: ICourse): boolean {
    if (course.isPurchased) {
      return false;
    }

    const allowedModes = [PreviewMode.publicMode];

    if (!this.loginService.isAnonymous$.getValue()) {
      allowedModes.push(PreviewMode.membersMode);
    }
    return !(chapter.inPreview && allowedModes.includes(chapter.previewMode));
  }

  public isChapterInMembersPreviewOnly(
    chapter: IChapter,
    course: ICourse
  ): boolean {
    if (course.isPurchased) {
      return false;
    }

    return chapter.inPreview && chapter.previewMode == PreviewMode.membersMode;
  }
}
