import {
  Component,
  Input,
  ViewEncapsulation,
  AfterViewInit
} from "@angular/core";
import ICourse, { IColumn } from "ecoreps_shared/model/course";
import { BehaviorSubject, Observable } from "rxjs";
import { CourseNavigationContext } from "../../../services/chapterNavigation.service";
import { SpaService } from "../../../services/spa.service";

@Component({
  selector: "breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class BreadcrumbsComponent implements AfterViewInit {
  @Input() course: Observable<ICourse>;
  @Input() column: Observable<IColumn>;
  @Input() columnSync: IColumn;
  @Input() learningOverview = false;
  @Input() checkout = false;
  @Input() home = false;

  public heading = new BehaviorSubject<string>(null);
  public progress: number;

  public _course: ICourse;
  public _column: IColumn;

  constructor(
    public navContext: CourseNavigationContext,
    public spaService: SpaService
  ) {}

  ngAfterViewInit(): void {
    this.spaService.course.subscribe((course) => {
      this._course = course;
      this.updateHeading();
    });
    this.spaService.column.subscribe((course) => {
      this._column = course;
      this.updateHeading();
    });

    this.updateHeading();
  }

  public updateHeading(): void {
    let heading = null;
    if (this._course) {
      heading = "Kursübersicht " + this._course.title;
    }
    if (this._column) heading = this._column.title;
    else if (this.columnSync) {
      heading = this.columnSync.title;
    }

    if (this.learningOverview) {
      heading = "Lernübersicht " + this._course.title + ":";
    }
    if (this.checkout) {
      heading = "Buchung";
    }
    if (this.home) {
      heading = "Herzlich Willkommen im Kursbereich :)";
    }

    this.heading.next(
      heading ? heading : "Herzlich Willkommen im Kursbereich :)"
    );
  }

  public showProgress(): boolean {
    return this.learningOverview;
  }
}
