import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";
import { environment } from "../../environments/environment";
import IUniversity from "ecoreps_shared/model/university";

@Injectable({
  providedIn: "root"
})
export class UniversityService {
  public getAllUniversities = (): Observable<IUniversity[]> => {
    return this.client
      .get<IUniversity[]>([environment.apiUrl, "universities"].join("/"))
      .pipe(shareReplay(1));
  };

  public getUniversityById = (
    universityId: string
  ): Observable<IUniversity> => {
    return this.client.get<IUniversity>(
      [environment.apiUrl, "universities", universityId].join("/")
    );
  };

  public sendFeedback = (
    universityId: string,
    feedback: { description: string; screenshot: string }
  ): Observable<IUniversity> => {
    return this.client.post<IUniversity>(
      [environment.apiUrl, "universities", universityId, "feedback"].join("/"),
      feedback,
      {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzZlN2ZlNDUzZDc3MGZlMDE4Y2FkMCIsImlhdCI6MTUzNDUxOTM3OSwiZXhwIjoxNTM0NTIyOTc5fQ.4A2lRZF4MPLAyMoywBkZCCGyCEejAsK-0hV_oPMWFAU"
        }
      }
    );
  };

  public setMainInstitution(id: string) {
    return this.client.post<IUniversity>(
      [environment.profileUrl, "university", id].join("/"),
      null
    );
  }

  constructor(private client: HttpClient) {}
}
