/**
 * Created by huck on 15.06.18
 */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import "rxjs/add/operator/map";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { animate, style, transition, trigger } from "@angular/animations";
import IComment, { CommentVisibility } from "ecoreps_shared/model/comment";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Observable } from "rxjs";
import { CommentsService } from "../../services/comments.service";
import { environment } from "environments/environment";

@Component({
  selector: "comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"],
  animations: [
    trigger("showRepliesAnimation", [
      transition(":enter", [
        style({ height: 0, opacity: 0 }),
        animate("500ms", style({ height: 300, opacity: 1 }))
      ]),
      transition(":leave", [
        style({ height: 300, opacity: 1 }),
        animate("500ms", style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class CommentComponent implements OnInit {
  @Input() public editMode = false;
  @Input() public comment: IComment = null;
  @Output() public addReply = new EventEmitter<IComment>();
  @Output() public updateComments = new EventEmitter<boolean>();

  public content: SafeHtml;
  public replyForm: FormGroup;
  public showReplies = true;
  public submitted = false;
  public expanded = {};
  public isExpandable = true;
  public CommentVisibility = CommentVisibility;

  public commentToEdit: { id: string; text: string } = {
    id: null,
    text: null
  };

  constructor(
    private sanitizer: DomSanitizer,
    private commentService: CommentsService
  ) {
    this.replyForm = new FormGroup({
      text: new FormControl("", [Validators.minLength(6), Validators.required])
    });
  }

  ngOnInit(): void {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.comment.text);
    this.isExpandable = this.comment.replies.length > 0;
  }

  public autoGrow(element): void {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + 6 + "px";
  }

  public get currentVisibility(): CommentVisibility {
    return this.comment.visibility || CommentVisibility.admin;
  }

  public responseAuthor(reply: IComment): string {
    return reply.author_name !== "ecoreps" && reply.author_name !== "studyprime"
      ? reply.author_name
      : environment.app;
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.replyForm.controls;
  }

  public toggleVisibility(): void {
    const current = this.currentVisibility;
    const newVisibility =
      current === CommentVisibility.admin
        ? CommentVisibility.published
        : CommentVisibility.admin;
    this.commentService
      .setVisibility((this.comment as any)._id, newVisibility)
      .subscribe(
        () => {
          this.updateComments.emit(true);
        },
        (error) => {
          console.error(error);
          alert("Fehler beim ändern des Kommentars");
        }
      );
  }

  public postReply(): Observable<IComment> {
    if (!this.replyForm.valid) {
      // TODO: better handling
      // alert(this.replyForm.errors.toString());
    }
    return this.commentService.postReply(
      (this.comment as any)._id,
      this.replyForm.value as IComment
    );
  }

  public submit(event: Event): void {
    this.submitted = true;
    if (this.replyForm.valid) {
      this.postReply().subscribe(
        () => {
          alert(
            "Danke für deine Frage. Sie erscheint hier, wenn sie beantwortet ist."
          );
          this.replyForm.reset();
          const textarea = jQuery(event.target).siblings("textarea");
          if (textarea.length > 0) {
            this.autoGrow(textarea[0]);
          }
          this.updateComments.emit(true);
        },
        (error) => {
          alert("Fehler beim hochladen deiner Frage: " + JSON.stringify(error));
        }
      );
    }
  }

  public toggleEditableComment(comment: IComment): void {
    if (this.commentToEdit.id !== comment._id) {
      this.commentToEdit = {
        id: comment._id,
        text: comment.text
      };
    } else {
      this.commentToEdit = {
        id: null,
        text: null
      };
    }
  }

  public sendToEdit(): void {
    this.commentService
      .modifyComment(this.commentToEdit.id, {
        _text: this.commentToEdit.text
      })
      .subscribe(() => {
        this.updateComments.emit(true);
      });
  }

  public deleteComment(id: string): void {
    this.commentService.deleteComment(id).subscribe(() => {
      this.updateComments.emit(true);
    });
  }

  public deleteCommentReply(commentId: string, replyId: string): void {
    this.commentService.deleteReply(commentId, replyId).subscribe(() => {
      this.updateComments.emit(true);
    });
  }
}
