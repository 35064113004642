import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { LoginService } from "./login.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: LoginService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.loggedInValue) {
      window.open(environment.landingPageUrl, "_self");
      return false;
    }

    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class AnonymouGuardService implements CanActivate {
  constructor(public auth: LoginService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    if (!this.auth.loggedInValue) {
      const token = await this.auth.getAnonymous().toPromise();
      return !!token;
    }
    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class orderGuardService implements CanActivate {
  constructor(public auth: LoginService, public router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.auth.loggedInValue) {
      this.auth.login();
    }

    if (this.auth.isAnonymous$.value) {
      this.auth.login(null, null, true);
    }

    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class AdminGuardService implements CanActivate {
  constructor(public auth: LoginService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.loggedInValue) {
      this.router.navigate(["login"]);
      return false;
    }
    if (!this.auth.currentIsAdmin) {
      return false;
    }
    return true;
  }
}
