import { BrowserModule } from "@angular/platform-browser";
import { InjectionToken, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./components/app.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoursePreviewComponent } from "./components/coursepreview/coursepreview.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { Ecospinner } from "./directives/ecospinner/ecospinner.component";
import { environment } from "../environments/environment";
import { NgxDnDModule } from "@swimlane/ngx-dnd";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TreeModule } from "angular-tree-component";
import { ClickOutsideModule } from "ng-click-outside";
import { JwtModule } from "@auth0/angular-jwt";
import { HttpErrorInterceptorProvider } from "./Interceptors/ErrorHandler.Interceptor";
import { ClientIdInterceptorProvider } from "./Interceptors/clientid.interceptor";

import { NgxSelectModule } from "ngx-select-ex";
import { SlickCarouselModule } from "ngx-slick-carousel";

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import localeFr from "@angular/common/locales/fr";
import { registerLocaleData } from "@angular/common";
import { MarkdownModule } from "ngx-markdown";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { EmbedVideo } from "ngx-embed-video/dist";
import { NgxPayPalModule } from "ngx-paypal";
import { EcospinnerModule } from "./directives/ecospinner/ecospinner.module";
import { SectionModule } from "./modules/members-access-modules/course/summary/section/section.module";
import { TopbarModule } from "./modules/public-access-modules/topbar/topbar.module";
import { FooterModule } from "./components/footer/footer.module";
import { SidebarModule } from "./modules/members-access-modules/sidebar/sidebar.module";
import { BreadcrumbsModule } from "./modules/members-access-modules/breadcrumbs/breadcrumbs.module";
import { appRoutes } from "./app.routes";

registerLocaleData(localeFr, "de");

export function tokenGetter(): string {
  return localStorage.getItem("id_token");
}

const externalUrlProvider = new InjectionToken("externalUrlRedirectResolver");

@NgModule({
  declarations: [AppComponent, CoursePreviewComponent],
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "top"
    }),
    EmbedVideo.forRoot(),
    RecaptchaV3Module,
    NgxDnDModule,
    SlickCarouselModule,
    ClickOutsideModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          "https://api.ecoreps.de",
          "api.ecoreps.de",
          "api2.ecoreps.de",
          "ecoreps.de",
          "https://ecoreps.de",
          "e-learning.ecoreps.de",
          "api.ecoreps.huck-it.de",
          "api.e-learning.ecoreps.de",
          "localhost:5000",
          "localhost:5001",
          "localhost:5002",
          "localhost:5003",
          "ecoreps-staging.ninf.de",
          "ecoreps-staging.ninf.de",
          "devapi.ecoreps.huck-it.de",
          "devapi2.ecoreps.huck-it.de",
          "devapi.ecoreps.huck-it.de",
          "api.studyprime.de",
          "api.studyprime.de"
        ]
      }
    }),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DropzoneModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    TreeModule.forRoot(),
    NgxSelectModule,
    MDBBootstrapModule.forRoot(),
    MarkdownModule.forRoot(),
    NgxPayPalModule,
    LayoutModule,
    EcospinnerModule,
    SectionModule,
    TopbarModule,
    FooterModule,
    SidebarModule,
    BreadcrumbsModule
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    {
      provide: externalUrlProvider,
      useValue: () => {
        const externalUrl = environment.logoutUrl;
        window.open(externalUrl, "_self");
      }
    },
    ClientIdInterceptorProvider,
    HttpErrorInterceptorProvider
  ],
  entryComponents: [Ecospinner],
  bootstrap: [AppComponent]
})
export class AppModule {}
