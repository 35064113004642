/**
 * Created by huck on 15.06.18
 */
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import "rxjs/add/operator/map";
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl
} from "@angular/platform-browser";
import { IVideoDisplayMode, IVideoWrapper } from "ecoreps_shared/model/section";
import { EmbedVideoService } from "ngx-embed-video/dist";

@Component({
  selector: "section-video",
  templateUrl: "./section-video.component.html",
  styleUrls: ["./section-video.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SectionVideoComponent implements OnInit {
  @Input() public editMode = false;
  @Input() public video: IVideoWrapper;

  public expanded: boolean = null;
  public iframe_html: SafeHtml;

  public transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private embedService: EmbedVideoService
  ) {}

  ngOnInit(): void {
    const vimeoFormat =
      /^(http:\/\/|https:\/\/)(player\.)?vimeo\.com\/(video\/)?([\d\/]+)([\?].*)?$/;
    this.video.isVimeo = vimeoFormat.test(this.video.url);
    if (this.video.isVimeo) {
      this.iframe_html = this.embedService.embed(this.video.url, {
        attr: {
          width: "800",
          height: "600",
          style: "max-width:800px;max-height:600px;"
        }
      });
    }
    this.expanded =
      this.video &&
      this.video.displayMode != null &&
      this.video.displayMode != IVideoDisplayMode.collapsed;
  }
}
