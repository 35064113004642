import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import IProfile from "ecoreps_shared/model/profile";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  public profile = new BehaviorSubject<IProfile>(null);

  public me(): BehaviorSubject<IProfile> {
    this.client
      .get<IProfile>([environment.profileUrl, "me"].join("/"))
      .subscribe((profile) => {
        this.profile.next(profile);
      });
    return this.profile;
  }

  constructor(private client: HttpClient) {}
}
