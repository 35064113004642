export const environment = {
  production: false,
  apiUrl: "https://api.ecoreps.de/v2/core",
  orderUrl: "https://api.ecoreps.de/v2/profile/order",
  profileUrl: "https://api.ecoreps.de/v2/profile",
  mediaUrl: "https://api.ecoreps.de/v2/core/",
  baseUrl: "https://studyprime.de/",
  cmsMediaURL: "https://cms.studyprime.de/ecoreps/assets",
  reCaptchaKey: "6LcREL8UAAAAAIY1t_D5GE20U95IJ9ZiBVkbNrM8",
  feedbackAddress:
    "incoming+huck-it/ecoreps/poc+ekrre5o901xp3nlprnvof0yjk@incoming.gitlab.com",
  mailgun_api_key: "5466f6abcd30b0dc7005e2fd0b7c1ce4-a3d67641-dcacf75a",
  mailgun_domain: "mg.huck-it.de",
  videoUploadUrl: "https://api.cloudinary.com/v1_1/ecoreps/video/upload",
  imageUploadUrl: "https://api.cloudinary.com/v1_1/ecoreps/image/upload",
  logoutUrl: "https://studyprime.de/",
  loginCallback: "https://studyprime.de/",
  landingPageUrl: "https://studyprime.de/",
  trackingID: "UA-159355052-2",
  auth0: {
    clientID: "GMLEsdjQpZsruUl3fHBENvUdMKQc9eJJ",
    domain: "auth.studyprime.de",
    responseType: "token id_token",
    redirectUri: "https://studyprime.de/",
    scope:
      "openid user_id email profile fullname picture user_metadata app_metadata https://ecoreps.de/roles https://ecoreps.de/purchased_courses expires_in"
  },
  paypal: {
    clientId:
      "ATjzWrF0Fnw2xZBX287w8hL5-39YeSKc8KootJD1XjMuaBGakaNzSVtkvFokqTAUqwcTlW6sLHx-B7Rw"
  },
  paypalKKL: {
    clientId:
      "AbsP6mHP4DTgSijbJJs--WGeNPuhoOpI0PSj1TTM2HTy34YX-4AHKguvD9ru5I0krD02DR9P7CEVWoc7"
  },
  build: {
    docker: {
      poll: 1000
    }
  },

  // The following attributes are in development and are subject to change, once we figured out how to nicely run
  // ecoreps and studyprime on the same code base
  // For now, I (Jonas) will use this simple switch:
  app: "studyprime", // for deving, change to ecoreps if you feel like it
  // includes the following helper functions because y not
  isEcoreps(): boolean {
    return environment.app === "ecoreps";
  },
  isStudyprime(): boolean {
    return environment.app === "studyprime";
  }
};
