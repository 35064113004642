import { landingPageContent as ecorepsLandingPageContent } from "./ecoreps/landing-page-content";
import { landingPageContent as studyprimeLandingPageContent } from "./studyprime/landing-page-content";
import { offersPageContent as ecorepsOffersPageContent } from "./ecoreps/offer-page-content";
import { offersPageContent as studyprimeOffersPageContent } from "./studyprime/offer-page-content";
import { footerContent as ecorepsFooterContent } from "./ecoreps/footer";
import { footerContent as studyprimeFooterContent } from "./studyprime/footer";

export const pagesConfig = {
  ecoreps: {
    landingPage: ecorepsLandingPageContent,
    offerPage: ecorepsOffersPageContent,
    footer: ecorepsFooterContent
  },
  studyprime: {
    landingPage: studyprimeLandingPageContent,
    offerPage: studyprimeOffersPageContent,
    footer: studyprimeFooterContent
  }
};
