import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs";
import { LoginService } from "../../../../services/login.service";
import { CourseNavigationContext } from "../../../../services/chapterNavigation.service";
import { UniversityService } from "../../../../services/university.service";
import { EcoSpinnerService } from "../../../../directives/ecospinner/ecospinner.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../../environments/environment";
import { BrowserService } from "../../../../services/browser.service";

@Component({
  selector: "topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})
export class TopbarComponent implements OnInit {
  public open: boolean;
  private lastScrollTop = 0;
  public hideNavBar = new BehaviorSubject<boolean>(false);
  public opaqueNavBar = new BehaviorSubject<boolean>(false);
  public environment = environment;
  public baseURL = environment.baseUrl;
  public isStudyprime = environment.isStudyprime();
  public isEcoreps = environment.isEcoreps();

  public loggedIn: Observable<boolean>;
  public idToken;

  public profileInfo: Observable<any>;
  public isAdmin: Observable<boolean>;

  public showUser = false;

  public isSafari: boolean;

  constructor(
    public loginService: LoginService,
    private navContext: CourseNavigationContext,
    private renderer: Renderer2,
    private universityService: UniversityService,
    private spinner: EcoSpinnerService,
    private snackBar: MatSnackBar,
    private browserService: BrowserService
  ) {
    this.loggedIn = loginService.loggedIn;
    this.profileInfo = this.loginService.profileInfo.asObservable();
  }

  ngOnInit(): void {
    this.open = false;
    this.isSafari = this.browserService.isSafari();
  }

  public toggleNav(value = null): void {
    this.open = value === null ? !this.open : !!value;
  }

  @HostListener("window:scroll", [])
  onWindowScroll(): void {
    const currentScrollTop = document.scrollingElement.scrollTop;
    this.hideNavBar.next(
      currentScrollTop > 0 && this.lastScrollTop < currentScrollTop
    );
    this.opaqueNavBar.next(currentScrollTop > 20);
    this.lastScrollTop = currentScrollTop;
  }

  public login(): void {
    this.loginService.login();
  }

  public register(): void {
    this.loginService.login(null, null, true);
  }

  public logout(): void {
    this.loginService.logout();
  }

  public logoUrl(): string {
    return this.isStudyprime
      ? "./assets/studyprime/svg/logo.svg"
      : "./assets/images/logo-short-3-blue.png";
  }

  public logoUrlNaked(): null | string {
    return this.isStudyprime ? null : "./assets/images/logo-2-white.png";
  }

  public userToggle(val = null): void {
    if (val === null) val = !this.showUser;
    this.showUser = val;
  }
}
