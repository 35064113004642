import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, share } from "rxjs/operators";
import { environment } from "../../environments/environment";

import IComment, { CommentVisibility } from "ecoreps_shared/model/comment";

@Injectable({
  providedIn: "root"
})
export class CommentsService {
  cachedCommentData: { [key: string]: IComment[] } = {};

  public getComments = (
    chapterId: string,
    sectionId: string,
    forceReload: boolean = false
  ): Observable<IComment[]> => {
    if (this.cachedCommentData[sectionId] && !forceReload) {
      return of(this.cachedCommentData[sectionId]);
    } else {
      const path = [environment.apiUrl, "chapter", chapterId];
      if (sectionId) {
        path.push("section", sectionId);
      }
      path.push("comments");
      return this.client.get<IComment[]>(path.join("/")).pipe(
        map((comments) => {
          this.cachedCommentData[sectionId] = comments;
          return this.cachedCommentData[sectionId];
        }),
        share()
      );
    }
  };

  public postComment = (
    chapterId: string,
    sectionId: string,
    comment: IComment
  ): Observable<IComment> => {
    const path = [environment.profileUrl, "comment", "chapter", chapterId];
    if (sectionId) {
      path.push("section", sectionId);
    }

    return this.client.post<IComment>(path.join("/"), comment).pipe(share());
  };

  public postReply = (
    commentId: string,
    comment: IComment
  ): Observable<IComment> => {
    const path = [environment.profileUrl, "comment", commentId, "reply"];
    return this.client.post<IComment>(path.join("/"), comment).pipe(share());
  };

  public setVisibility = (
    commentId: string,
    visibility: CommentVisibility
  ): Observable<IComment> => {
    const path = [
      environment.profileUrl,
      "comment",
      commentId,
      "visibility",
      visibility
    ];
    return this.client.get<IComment>(path.join("/")).pipe(share());
  };

  public modifyComment(commentId: string, dataToBeModify: { _text: string }) {
    const path = [environment.profileUrl, "comment", commentId];
    return this.client
      .put<IComment>(path.join("/"), dataToBeModify)
      .pipe(share());
  }

  public deleteComment(commentId: string) {
    return this.client
      .delete<IComment>(
        [environment.profileUrl, "comment", commentId].join("/")
      )
      .pipe(share());
  }

  public deleteReply(commentId: string, replyId: string) {
    return this.client
      .delete<IComment>(
        [environment.profileUrl, "comment", commentId, "reply", replyId].join(
          "/"
        )
      )
      .pipe(share());
  }

  constructor(private client: HttpClient) {}
}
