import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SectionVideoComponent } from "./section-video.component";

@NgModule({
  declarations: [SectionVideoComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [SectionVideoComponent]
})
export class SectionVideoModule {}
