/**
 * Created by huck on 15.06.18
 */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import "rxjs/add/operator/map";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import ISection, {
  SectionTypeInfo,
  IIllustrationSize,
  IIllustrationWrapper,
  ISectionTypeInfo,
  IVideoWrapper,
  SectionImagePosition,
  SectionType
} from "ecoreps_shared/model/section";
import { environment } from "../../../../../../../environments/environment";
import { animate, style, transition, trigger } from "@angular/animations";
import { Observable, BehaviorSubject } from "rxjs";
import { ProgressState } from "ecoreps_shared/model/profile";
import { LoginService } from "../../../../../../services/login.service";

@Component({
  selector: "course-section",
  templateUrl: "./section.component.html",
  styleUrls: ["./section.component.scss"],
  animations: [
    trigger("showSectionAnimation", [
      transition(":enter", [
        style({ opacity: 0, height: 0 }),
        animate("500ms", style({ opacity: 1, height: "auto" }))
      ]),
      transition(":leave", [
        style({ opacity: 1, height: "auto" }),
        animate("500ms", style({ opacity: 0, height: 0 }))
      ])
    ]),
    trigger("showVideoAnimation", [
      transition(":enter", [
        style({ height: 0, opacity: 0 }),
        animate("500ms", style({ height: 300, opacity: 1 }))
      ]),
      transition(":leave", [
        style({ height: 300, opacity: 1 }),
        animate("500ms", style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class SectionComponent implements OnInit, AfterViewInit {
  content: SafeHtml;

  classString: string;
  boxClassString: string;

  title: string;

  public baseURL = environment.mediaUrl + "/";
  public innerWidth: any;

  public ImagePosition = SectionImagePosition;
  public images: IIllustrationWrapper[] = [];
  public mobileImages: IIllustrationWrapper[] = [];
  public showSection = false;
  public showMobile = false;
  public sectionTypes = SectionType;
  public expanded = {};
  public info: ISectionTypeInfo;
  public titleDidChange = false;

  @Input() public section: ISection;
  @Input() public editMode = false;
  @Input() public titlePrefix = "";
  @Input() public chapterId = "";
  @Input() public allowComments = false;
  @Input() public itsAdminMode = false;
  @Input() public progressState: Observable<ProgressState> = null;
  @Output() public notUnderstood = new EventEmitter<void>();
  @Output() public partlyUnderstood = new EventEmitter<void>();
  @Output() public fullyUnderstood = new EventEmitter<void>();
  @Output() public imagesChanged = new EventEmitter<IIllustrationWrapper[]>();
  @Output() public mobileImagesChanged = new EventEmitter<
    IIllustrationWrapper[]
  >();
  @Output() public videosChanged = new EventEmitter<IVideoWrapper[]>();

  @Input() changeEvaluation: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  onClickEvaluation(): void {
    this.changeEvaluation.next(false);
  }

  constructor(
    private sanitizer: DomSanitizer,
    public loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.info = new SectionTypeInfo(this.section.type);
    this.showSection = !this.info.expandable || this.editMode;
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.section.content);
    this.classString = this.info.classString;
    this.boxClassString = this.info.boxClassString;
    this.innerWidth = window.innerWidth;

    this.showMobile = !!(
      innerWidth < 500 &&
      this.section.mobileImages &&
      this.section.mobileImages.length
    );

    this.title = this.section.hasTitle
      ? this.section.title || this.info.defaultTitle
      : null;

    this.images = this.section.images;
    this.mobileImages = this.section.mobileImages;

    jQuery(function () {
      setTimeout(function () {
        // and here we add the onscroll listener. Yep, kill me pls
        jQuery(".math_block").on("scroll", function (e) {
          // so, we will add a cheat class, that doesn't really change the element, but forces the background to redraw. Afterwards we remove the class a-sync again, so it actually does redraw and it works more than once. Chrome needs a css change in order to redraw
          const el = jQuery(e.target);
          el.toggleClass("scroll-shadow-cheat-kill-me-later-for-this");
        });
      }, 3000); // just fucking kill me
    });
  }

  ngAfterViewInit(): void {
    //copy protection
    jQuery("img").on("contextmenu", function () {
      return false;
    });
    jQuery("video").on("contextmenu", function () {
      return false;
    });
    $("img").on("dragstart", function (event) {
      event.preventDefault();
    });
    $("video").on("dragstart", function (event) {
      event.preventDefault();
    });
  }

  public toggleShow(): void {
    this.showSection = !this.showSection;
  }

  public toggleExpanded(video: IVideoWrapper): void {
    if (this.expanded.hasOwnProperty(video.public_id)) {
      this.expanded[video.public_id] = !this.expanded[video.public_id];
    } else {
      this.expanded[video.public_id] = true;
    }
  }

  public classForImageSize(size: IIllustrationSize): string {
    let sizeClass: string;
    switch (size) {
      case IIllustrationSize.small:
        sizeClass = "small";
        break;
      case IIllustrationSize.medium:
        sizeClass = "medium";
        break;
      case IIllustrationSize.large:
        sizeClass = "large";
        break;
      default:
        console.error("no size class for " + size);
        sizeClass = "small";
    }
    return "polaroid  col-xs " + sizeClass;
  }

  public updateImages(mobile: boolean): void {
    if (mobile) {
      this.mobileImagesChanged.next(this.mobileImages);
    } else {
      this.imagesChanged.next(this.images);
    }
  }

  public updateVideos(): void {
    this.videosChanged.next(this.section.videos);
  }

  public dropImage(
    wrapperToDelete: IIllustrationWrapper,
    mobile: boolean
  ): void {
    if (mobile) {
      this.mobileImages = this.mobileImages.filter(
        (wr) => wr.url !== wrapperToDelete.url
      );
    } else {
      this.images = this.images.filter((wr) => wr.url !== wrapperToDelete.url);
    }

    this.updateImages(mobile);
  }

  public dropVideo(video: IVideoWrapper): void {
    this.section.videos = this.section.videos.filter(
      (wr) => wr.public_id != video.public_id
    );
    this.updateVideos();
  }

  public titleChanged(): void {
    this.titleDidChange = true;
  }

  public urlForImage(location: string): string {
    if (
      location.trim().startsWith("https://") ||
      location.trim().startsWith("http://")
    ) {
      return location;
    }

    return this.baseURL + location;
  }

  public get isNotUnderstood(): Observable<any> {
    return this.progressState.map(
      (state) => state == ProgressState.notUnderstood
    );
  }

  public get isFullyUnderstood(): Observable<boolean> {
    return this.progressState.map(
      (state) => state == ProgressState.fullyUnderstood
    );
  }

  public get isPartlyUnderstood(): Observable<boolean> {
    return this.progressState.map(
      (state) => state == ProgressState.partlyUnderstood
    );
  }
}
