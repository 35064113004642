import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "k-accordeon",
  templateUrl: "./k-accordeon.component.html",
  styleUrls: ["./k-accordeon.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccordeonComponent implements OnInit, AfterViewInit {
  @Input() title = "";
  @Input() icon = "";
  @Input() toggleClass = "plus"; // either this, or caret
  @Input() grow = true; // either this, or caret

  @Output() public onToggle = new EventEmitter<boolean>();

  @ViewChild("content", { static: false }) body: ElementRef;
  @ViewChild("component", { static: false }) component: ElementRef;

  public open = false;

  ngOnInit(): void {
    const self = this;
    (<any>document).fonts.ready.then(function () {
      self.updateHeight();
    });
  }

  public toggle(): void {
    this.open = !this.open;
    this.onToggle.emit(this.open);
  }

  public onResize(): void {
    this.updateHeight();
  }

  public updateHeight(): void {
    if (this.grow) {
      this.component.nativeElement.style.setProperty(
        "--height",
        this.body.nativeElement.scrollHeight + "px"
      );
    } else {
      this.component.nativeElement.style.setProperty("--height", "unset");
    }
  }

  ngAfterViewInit(): void {
    this.updateHeight();
  }
}
