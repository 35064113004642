import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { ISidebarConfig } from "../helper/ISidebarConfig";

@Injectable({
  providedIn: "root"
})
export class SidebarService {
  public context: BehaviorSubject<ISidebarConfig>;

  public next(context: ISidebarConfig): void {
    this.context.next(context);
  }

  constructor() {
    this.context = new BehaviorSubject<ISidebarConfig>({ show: false });
  }
}
