import { OnDestroy } from "@angular/core";
import DisposeBag from "ecoreps_shared/utils/DisposeBag";

export default class BasicRxComponent implements OnDestroy {
  protected bag = new DisposeBag();

  ngOnDestroy(): void {
    this.bag.dispose();
  }
}
