import { Injectable } from "@angular/core";
import { BehaviorSubject, interval, Observable } from "rxjs";
import { Router } from "@angular/router";
import { Preference } from "ecoreps_shared/model/preference";
import * as auth0 from "auth0-js";
import { environment } from "../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  withLatestFrom
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import DisposeBag from "../../../../shared/utils/DisposeBag";
import ILoginState from "../helper/LoginState";

(window as any).global = window;

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: "root"
})
export class LoginService {
  /*
  * clientID: 'spTE5xNGMie3QW0cFFkuhd3AZfZ0Adq4',
      domain: 'coreps-elearning.eu.auth0.com',
      responseType: 'token id_token',
      audience:"https://ecoreps-elearning.eu.auth0.com/api/v2/",
  * */

  auth0 = new auth0.WebAuth(environment.auth0);

  public profileInfo = new BehaviorSubject<any>(null);
  public refreshTokens = new BehaviorSubject<any>(null);

  private _loggedIn = new BehaviorSubject<boolean>(false);
  private bag = new DisposeBag();

  constructor(
    private router: Router,
    private jwt: JwtHelperService,
    private client: HttpClient
  ) {
    this._loggedIn.next(localStorage.getItem("loggedIn") === "true");
    this.bag.add(
      interval(15000)
        .pipe(
          withLatestFrom(this.loggedIn),
          filter((args) => args[1]),
          switchMap(() => this.verifySession())
        )
        .subscribe()
    );
    this.refreshAuth();
    this.isAdmin = this.profileInfo.pipe(
      map((info) => {
        if (info && info !== undefined) {
          const roles = info["https://ecoreps.de/roles"];
          if (roles) {
            return roles.indexOf("admin") > -1;
          }
        }
        return false;
      })
    );
    this.profileInfo
      .pipe(
        map((info) => {
          if (info) {
            this.isAnonymous$.next(!!info.anonymousId);
          } else {
            this.isAnonymous$.next(false);
          }
        })
      )
      .subscribe();
  }

  public get loggedIn(): Observable<boolean> {
    return this._loggedIn.pipe(shareReplay(1));
  }

  public isAdmin: Observable<boolean>;
  public isAnonymous$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public get loggedInValue(): boolean {
    return this._loggedIn.getValue();
  }

  public storePreference(preference: Preference, value: string): void {
    localStorage.setItem(preference.toString(), value);
  }

  public storePreferenceForImmediateKey(
    preference: string,
    value: string
  ): void {
    localStorage.setItem(preference, value);
  }

  //wrapper for localStorage
  public retrievePreferenceByImmediateKey(preference: string): string {
    return localStorage.getItem(preference);
  }

  public retrievePreference(preference: Preference): string {
    return localStorage.getItem(preference.toString());
  }

  public reLoginRestoringState() {
    const nonce = this.generateUUID();
    const state = this.loginstate;
    this.logout(false);

    return this.login(nonce, state);
  }

  public refreshToken(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.auth0.checkSession({}, (err, result) => {
        if (!err) {
          this.setSession(result);
          this.refreshAuth();
          this.refreshTokens.next(this.profileInfo.getValue());
          resolve();
        } else {
          reject(err);
        }
      });
    });
  }

  public get nonce(): string {
    return this.retrievePreference(Preference.nonce);
  }

  public get loginstate(): ILoginState {
    if (!this.nonce) {
      return null;
    }
    const stateValue = localStorage.getItem(this.nonce);
    if (!stateValue) {
      return null;
    }
    return JSON.parse(stateValue) as ILoginState;
  }

  public setLoginstate(nonce: string, state: ILoginState) {
    localStorage.setItem(nonce, JSON.stringify(state));
  }

  private clearNonceIfNeeded() {
    if (this.nonce) {
      localStorage.removeItem(this.nonce);
      localStorage.removeItem(Preference.nonce);
    }
  }

  private generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  public loginBookingCourse = (
    course: string = null,
    register: boolean = false
  ): void => {
    let state: ILoginState = null;

    if (course) {
      state = {
        bookmarkCourse: course
      };
    }
    localStorage.setItem("redirectUrl", "/order/" + course);

    const nonce = this.generateUUID();
    this.login(nonce, state, register);
  };

  public login(
    nonce: string = null,
    state: ILoginState = null,
    register: boolean = false
  ): Promise<any> {
    const isAnonymous = this.isAnonymous$.value;
    const options = {};

    if (register) {
      options["mode"] = "signUp";
      options["screen_hint"] = "signup";
      options["initialScreen"] = "signUp";
    }

    if (isAnonymous) {
      this.logout();
      return this.auth0.authorize(options);
    }

    this.clearNonceIfNeeded();
    if (this.isAuthenticated()) {
      return;
    }

    if (nonce) {
      this.storePreference(Preference.nonce, nonce);
      if (state) {
        this.setLoginstate(nonce, state);
        //options['redirect_uri'] = environment.baseUrl + 'order/' + state.bookmarkCourse
      }
    }

    if (nonce) {
      options["state"] = nonce;
    }

    return this.auth0.authorize(options);
  }

  public id_token(): string {
    const parsed = this.jwt.decodeToken(localStorage.getItem("id_token"));

    return parsed;
  }

  public verifySession = (): Observable<void> => {
    const anonymous = localStorage.getItem("anonymous");
    return !anonymous
      ? this.client.get<void>([environment.profileUrl, "session"].join("/"))
      : new Observable();
  };

  public logout(redirect: boolean | { returnTo: string } = true): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("is_loggedIn");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("anonymous");

    if (redirect) {
      this.auth0.logout({ returnTo: environment.logoutUrl });
    }
  }

  public get currentIsAdmin(): boolean {
    const profileInfo = this.profileInfo.value;
    if (!profileInfo || !profileInfo["https://ecoreps.de/roles"]) {
      return false;
    }
    return profileInfo["https://ecoreps.de/roles"].indexOf("admin") >= 0;
  }

  //Auth0 Methods

  public handleAuthentication(callback: () => void): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.refreshAuth();
        if (callback) {
          callback();
        }
        const customRedirectLink =
          localStorage.getItem("redirectUrl") || "/mycourses";
        localStorage.removeItem("redirectUrl");
        this.router.navigate([customRedirectLink]);
        getWindow().dataLayer = getWindow().dataLayer || [];
        getWindow().dataLayer.push({
          event: "pageview_login"
        });
      } else if (err) {
        this.router.navigate(["/home"]);
        console.error(err);
      }
      callback();
    });
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    this.profileInfo.next(authResult.idToken);
  }

  private get purchasedCourses() {
    return this.profileInfo.value["https://ecoreps.de/purchased_courses"] || [];
  }

  public hasPurchasedCourse(courseId: string): boolean {
    //console.info('checking', this.purchasedCourses, courseId);
    return this.currentIsAdmin || this.purchasedCourses.indexOf(courseId) >= 0;
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at") || "{}");
    return new Date().getTime() < expiresAt;
  }

  private refreshAuth() {
    const auth = this.isAuthenticated();
    this.profileInfo.next(this.id_token());
    this._loggedIn.next(auth);
  }

  public getAnonymous() {
    return this.client
      .get<{ token: string }>(
        [environment.profileUrl, "get-anonymous-token"].join("/"),
        {
          responseType: "json"
        }
      )
      .pipe(
        map((jwt) => {
          const token = jwt.token;
          const decodedToken = this.jwt.decodeToken(token);
          const date = new Date();
          const someExpireDate = date.setDate(date.getDate() + 1);
          const expiresAt = JSON.stringify(
            someExpireDate * 1000 + new Date().getTime()
          );
          localStorage.setItem("access_token", token);
          localStorage.setItem("id_token", token);
          localStorage.setItem("expires_at", expiresAt);
          localStorage.setItem("anonymous", decodedToken.anonymousId);
          this.refreshAuth();
          return token;
        }),
        catchError((err) => {
          console.error({ err });
          return err;
        })
      );
  }
}
