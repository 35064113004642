import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { _throw } from "rxjs/observable/throw";
import { Router } from "@angular/router";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
import { LoginService } from "../services/login.service";
import { catchError } from "rxjs/internal/operators";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private loginService: LoginService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status == 404) {
          this.router.navigateByUrl("/not-found", {
            replaceUrl: true
          });
          return new EmptyObservable();
        } else if (error instanceof HttpErrorResponse && error.status == 401) {
          this.loginService.logout();
          this.router.navigateByUrl("/not-authenticated", {
            replaceUrl: true
          });
          return new EmptyObservable();
        } else if (error instanceof HttpErrorResponse && error.status == 403) {
          this.router.navigateByUrl("/forbidden", {
            replaceUrl: true
          });
          return new EmptyObservable();
        } else return _throw(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true
};
