import {
  Component,
  Injectable,
  Input,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "ecospinner",
  styleUrls: ["./ecospinner.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  template: `
    <div *ngIf="(isLoading | async) && !isStudyprime" class="ecospinner">
      <span class="font-family:arial"
        >ec<img
          src="assets/images/circle.png"
          class="rotating"
          alt="circle"
          width="120"
          height="120"
        />reps
      </span>
    </div>
    <div *ngIf="(isLoading | async) && isStudyprime" class="ecospinner">
      <style type="text/css">
        #loading-screen {
          display: grid;
          align-items: center;
          justify-items: center;
          background-color: transparent;
        }

        #loading-screen > * {
          width: 300px;
          height: 200px;
          display: grid;
          justify-items: center;
          align-items: center;
          position: relative;
        }

        @media screen and (max-width: 768px) {
          #loading-screen > * {
            width: auto;
          }
        }

        #loading-screen .text {
          font-family: sans-serif;
        }

        #loading-screen .spinner {
          width: 100px;
          height: 100px;
          animation: rotator 2s linear infinite;
        }

        @keyframes rotator {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(320deg);
          }
        }

        .path {
          stroke-dasharray: 187;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation: dash 2s ease-in-out infinite,
            colors 2s ease-in-out infinite;
        }
        @keyframes colors {
          0% {
            stroke: #4285f400;
          }
          5% {
            stroke: #4285f4;
          }
          95% {
            stroke: #4285f4;
          }
          100% {
            stroke: #4285f400;
          }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: 187;
          }
          50% {
            stroke-dashoffset: 47;
            transform: rotate(135deg);
          }
          99.9% {
            stroke-dashoffset: 185;
            transform: rotate(450deg);
          }
        }

        #loading-screen .lock {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 82px;
        }
        #loading-screen .lock svg {
          width: 30px;
          height: 30px;
        }
      </style>
      <div id="loading-screen">
        <div>
          <div class="spinner">
            <!-- hm, loading of the page won't initialize fa -->
            <svg
              class="spinner"
              width="65px"
              height="65px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="path"
                fill="none"
                stroke-width="6"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
          <div class="lock">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!-- Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M416 224H384V128C384 57 327 0 256 0S128 57 128 128V224H96C61 224 32 253 32 288V448C32 483 61 512 96 512H416C451 512 480 483 480 448V288C480 253 451 224 416 224ZM144 128C144 66 194 16 256 16S368 66 368 128V224H144V128ZM464 448C464 474 442 496 416 496H96C70 496 48 474 48 448V288C48 262 70 240 96 240H416C442 240 464 262 464 288V448ZM256 320C238 320 224 334 224 352V384C224 402 238 416 256 416S288 402 288 384V352C288 334 274 320 256 320ZM272 384C272 393 265 400 256 400S240 393 240 384V352C240 343 247 336 256 336S272 343 272 352V384Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  `
})
export class Ecospinner implements OnInit {
  @Input() public isLoading: Observable<boolean> = of(true);
  @Input() public fullScreen = false;

  public isStudyprime = false;

  public ngOnInit(): void {
    this.isStudyprime = environment.isStudyprime();
  }
}

@Injectable({
  providedIn: "root"
})
export class EcoSpinnerService {
  constructor(private overlay: Overlay) {}

  open(): OverlayRef {
    // Returns an OverlayRef (which is a PortalHost)

    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-dark-backdrop",
      panelClass: "tm-file-preview-dialog-panel",
      positionStrategy: positionStrategy
    });

    // Create ComponentPortal that can be attached to a PortalHost
    const spinner = new ComponentPortal(Ecospinner);

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(spinner);
    return overlayRef;
  }
}
