import { Pipe, PipeTransform, NgModule } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import "ecoreps_shared/utils/string_extension";
import { CommonModule } from "@angular/common";

declare let katex;

@Pipe({ name: "renderMath" })
export class RenderMathPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, args?: any[]): SafeHtml {
    if (value == null) {
      return "";
    }
    function renderer(arg: string) {
      const input = arg.split("$").join("");
      const element = document.createElement("span");
      katex.render(input, element, {
        throwOnError: false
      });
      return element.innerHTML;
      //return this.sanitizer.bypassSecurityTrustHTML(element.innerHTML);
    }

    value = value.replace(/\$([^\$.*]{1,})\$/gm, renderer);

    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [RenderMathPipe],
  imports: [CommonModule],
  exports: [RenderMathPipe]
})
export class RenderMathPipeModule {}
