import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileService } from "./profile.service";
import { CourseService } from "./course.service";
import { filter } from "rxjs/operators";
import ICourse, { IColumn } from "ecoreps_shared/model/course";
import { CourseNavigationContext, PageType } from "./chapterNavigation.service";
import { IChapter } from "ecoreps_shared/model/chapter";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";
import IProfile from "ecoreps_shared/model/profile";

@Injectable({
  providedIn: "root"
})
export class SpaService {
  public course = new BehaviorSubject<ICourse>(null);
  public column = new BehaviorSubject<IColumn>(null);
  public chapter = new BehaviorSubject<IChapter>(null);
  public pageType = new BehaviorSubject<PageType>(null);

  constructor(
    private profileService: ProfileService,
    private courseService: CourseService,
    private navContext: CourseNavigationContext,
    private loginService: LoginService,
    private router: Router
  ) {
    this.navContext.route.subscribe((route) => {
      this.course.next(this.addCourseInfo(route.course));
      this.column.next(
        Array.isArray(route.column) ? route.column[0] : route.column
      );
      this.chapter.next(route.chapter);
      this.pageType.next(route.pageType);
    });

    this.profileNotNull.subscribe(() => {
      this.course.next(this.addCourseInfo(this.course.getValue()));
    });

    this.loginService.isAdmin.subscribe((isAdmin) => {
      if (isAdmin) {
        this.course.next(this.addCourseInfo(this.course.getValue()));
      }
    });
  }

  public addCourseInfo(course: ICourse): ICourse {
    if (course) {
      const profile = this.profile.getValue() as any;
      if (profile) {
        course.isPurchased =
          (profile.purchasedCourses &&
            !!profile.purchasedCourses.find((id) => id == course._id)) ||
          this.loginService.currentIsAdmin;
        profile.progress.forEach((progress) => {
          if (progress.course_id == course._id) {
            course.totalProgress = progress.progressTotal;
            course.processedProgress = progress.progressProcessedTotal;
            // todo: Also set Chapter progress
          }
        });
      }
    }
    return course;
  }

  public updateProgress(): void {
    const course = this.course.getValue();
    if (course) {
      this.courseService.progressSummary(course).subscribe((res) => {
        if (this.course.getValue()) {
          course.processedProgress = res.progressProcessed;
          course.totalProgress = res.progressTotal;
          this.course.next(course);
        }
      });
    }
  }

  public getFirstChapterWithContent(column: IColumn) {
    // @ts-ignore
    const res = this.getFirstChapterWithContentRecursive(column.chapters);
    const first = column.chapters[0] || null;
    return res || first;
  }

  protected getFirstChapterWithContentRecursive(
    chapters: IChapter[],
    res = null
  ) {
    chapters.forEach((chapter) => {
      if (res) return;
      if (chapter.hasSections) {
        res = chapter;
      } else if (chapter.subchapters.length) {
        res = this.getFirstChapterWithContentRecursive(
          chapter.subchapters,
          res
        );
      }
    });

    return res;
  }

  public orderCourse(course: ICourse): void {
    if (this.loginService.loggedInValue) {
      if (course.isPurchased) {
        this.router.navigateByUrl("/courses/" + course.url_slug);
      } else {
        if (this.loginService.isAnonymous$.getValue()) {
          this.loginService.loginBookingCourse(course.url_slug, true);
        } else {
          this.router.navigateByUrl("/order/" + course.url_slug);
        }
      }
    } else {
      if (this.loginService.isAnonymous$.getValue()) {
        this.loginService.loginBookingCourse(course.url_slug, true);
      } else {
        this.loginService.getAnonymous().subscribe(() => {
          this.loginService.loginBookingCourse(course.url_slug, true);
        });
      }
    }
  }

  get profile(): BehaviorSubject<IProfile> {
    return this.profileService.profile;
  }

  get profileNotNull(): Observable<IProfile> {
    return this.profileService.profile.pipe(filter((p) => !!p));
  }
}
