import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RouterModule } from "@angular/router";
import { ProgressBarModule } from "../progress-bar/progress-bar.module";
import { VarDirective } from "../../../utils/varDirective";
import { RenderMathPipeModule } from "../../../pipe/renderMath";
import { ModalModule } from "../../../shared-components/modal/modal.module";
import { RemoveWhitespacesPipeModule } from "../../../pipe/removeWhiteSpaces";

@NgModule({
  declarations: [SidebarComponent, VarDirective],
  exports: [SidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    ProgressBarModule,
    RenderMathPipeModule,
    ModalModule,
    RemoveWhitespacesPipeModule
  ]
})
export class SidebarModule {}
