/**
 * Created by huck on 15.06.18
 */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import "rxjs/add/operator/map";
import { SafeHtml } from "@angular/platform-browser";
import IComment from "ecoreps_shared/model/comment";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import ISection from "ecoreps_shared/model/section";
import BasicRxComponent from "../BasicRxComponent";
import { CommentsService } from "../../services/comments.service";
import { BehaviorSubject, Observable } from "rxjs";
import { LoginService } from "../../services/login.service";
import { IChapter } from "ecoreps_shared/model/chapter";
@Component({
  selector: "comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent extends BasicRxComponent implements OnInit {
  content: SafeHtml;

  @Input() public editMode = false;
  @Input() public section: ISection;
  @Input() public chapterId: string; // OOP at it's best
  @Input() public chapter: IChapter;
  @Input() public style = "";
  @Output() public addComment = new EventEmitter<IComment>();

  @ViewChild("accordion", { static: true }) accordion;

  public newCommentForm: FormGroup;
  public expanded = false;
  public isAnonymous: BehaviorSubject<boolean> = this.loginService.isAnonymous$;
  public submitted = false;
  public some: string;

  private comments = new BehaviorSubject<IComment[]>(null);
  public subjectToComment = this.comments.asObservable();

  constructor(
    private commentService: CommentsService,
    private loginService: LoginService
  ) {
    super();
    this.newCommentForm = new FormGroup({
      text: new FormControl("", [Validators.minLength(5), Validators.required])
    });
  }

  ngOnInit(): void {
    if (this.section)
      this.section.stats.commentCount =
        this.section.stats.commentCount === null ||
        this.section.stats.commentCount === undefined
          ? 0
          : this.section.stats.commentCount;
    else if (this.chapter) {
      this.chapter.stats.commentCount =
        this.chapter.stats.commentCount === null ||
        this.chapter.stats.commentCount === undefined
          ? 0
          : this.chapter.stats.commentCount;
    }

    this.bag.add(
      this.comments.subscribe(() => {
        if (this.comments.getValue()) this.accordion.updateHeight();
      })
    );
  }

  public onExpand(value: boolean): void {
    if (value && this.comments.value == null) {
      this.loadComments();
    }
  }

  public loadComments(getFreshData?: boolean): void {
    this.bag.add(
      this.commentService
        .getComments(
          this.chapterId,
          this.section ? this.section._id : null,
          getFreshData
        )
        .subscribe((comments) => {
          this.comments.next([...comments]);
        })
    );
  }

  public postComment(): Observable<IComment> {
    if (!this.newCommentForm.valid) {
      // TODO: better handling
      alert("Bitte prüfe dein Kommentar auf Fehler !");
    }
    return this.commentService.postComment(
      this.chapterId,
      this.section ? this.section._id : null,
      this.newCommentForm.value as IComment
    );
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.newCommentForm.controls;
  }

  public submit(event: Event): void {
    this.submitted = true;
    if (this.newCommentForm.valid) {
      this.postComment().subscribe(
        () => {
          alert(
            "Danke für deine Frage. Sie erscheint hier, wenn sie beantwortet ist."
          );
          this.newCommentForm.reset();
          const textarea = jQuery(event.target).siblings("textarea");
          if (textarea.length > 0) {
            this.autoGrow(textarea[0]);
          }
          this.loadComments(true);
        },
        (error) => {
          alert("Fehler beim hochladen deiner Frage: " + JSON.stringify(error));
        }
      );
    } else {
      alert(
        "Leider ist ein Fehler aufgetreten - bitte überprüfe deine Angaben."
      );
    }
  }

  public autoGrow(element): void {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + 6 + "px";
  }
}
