import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from "@angular/core";
import { ProgressState } from "ecoreps_shared/model/profile";
import { Observable } from "rxjs";
import { LoginService } from "../../../../services/login.service";
import { SpaService } from "../../../../services/spa.service";

@Component({
  selector: "progress-controls",
  templateUrl: "progress-controls.component.html",
  styleUrls: ["progress-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProgressControlsComponent implements OnInit, OnChanges {
  @Output() public notUnderstood = new EventEmitter<void>();
  @Output() public partlyUnderstood = new EventEmitter<void>();
  @Output() public fullyUnderstood = new EventEmitter<void>();

  @Input() public progressState: Observable<ProgressState>;
  @Input() public compact = false;
  @Input() public additionalButtonClass = "";
  @Input() public style = "";
  @Input() public parentEvent = "";

  constructor(private loginService: LoginService) {}

  public isPartlyUnderstood: boolean;
  public isFullyUnderstood: boolean;
  public isNotUnderstood: boolean;
  public ProgressState = ProgressState;
  public console = console;
  public isAnonymous: boolean = this.loginService.isAnonymous$.value;
  showEvaluation: boolean;

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  get isObservable(): boolean {
    return this.progressState instanceof Observable;
  }

  showEvaluationContainer(event: Event): void {
    this.showEvaluation = true;
    event.stopPropagation();
  }

  makeEvaluate(evaluate: EventEmitter<void>): void {
    if (!this.isAnonymous) {
      evaluate.emit();
      this.showEvaluation = false;
    }
  }

  emitNotUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.notUnderstood.emit();
    }
  }

  emitPartlyUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.partlyUnderstood.emit();
    }
  }

  emitFullyUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.fullyUnderstood.emit();
    }
  }

  private refresh(): void {
    if (!(this.progressState instanceof Observable)) {
      this.isFullyUnderstood =
        this.progressState == ProgressState.fullyUnderstood;
      this.isPartlyUnderstood =
        this.progressState == ProgressState.partlyUnderstood;
      this.isNotUnderstood = this.progressState == ProgressState.notUnderstood;
    }
    if (this.parentEvent) {
      this.showEvaluation = false;
    }

    if (!this.showEvaluation) {
      this.parentEvent = "";
    }
  }
}
