import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { environment } from "environments/environment";

import { Observable } from "rxjs";

export class ClientIdInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clientId = environment.isEcoreps() ? "100" : "200";

    const clonedRequest = req.clone({
      headers: req.headers.append("ClientId", clientId)
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}

export const ClientIdInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ClientIdInterceptor,
  multi: true
};
