import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProgressControlsComponent } from "./progress-controls.component";

@NgModule({
  declarations: [ProgressControlsComponent],
  imports: [CommonModule],
  exports: [ProgressControlsComponent]
})
export class ProgressControlsModule {}
