import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "k-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class ModalComponent {
  @Input() closable = true;
  @Input() cancelable = true;
  @Input() submittable = true;
  @Input() submitText = "senden";
  @Input() title = "";
  @Input() show = false;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAction: EventEmitter<void> = new EventEmitter<void>();

  public backdropClick(): void {
    this.close();
  }

  public close(): void {
    if (this.closable) {
      this.onCancel.emit();
      this.onAction.emit();
    }
  }

  public accept(): void {
    if (this.submittable) {
      this.onSubmit.emit();
      this.onAction.emit();
    }
  }
}
