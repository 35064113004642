import {
  AdminGuardService,
  AnonymouGuardService,
  AuthGuardService
} from "./services/AuthenticationGuard";
import { LoggedInGuard } from "./services/LoggedInGuard";
import { Routes } from "@angular/router";

export const memberRoutes = [
  {
    path: "order/:courseId",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/course/order-course/order-course.module"
      ).then((m) => m.OrderCourseModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "courses",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/course/course-component/course.module"
      ).then((m) => m.CourseModule)
  },
  {
    path: "mycourses",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/mycourses/mycourses.module"
      ).then((m) => m.MyCoursesModule),
    canActivate: [AnonymouGuardService]
  }
];

export const ecorepsRoutes = [
  {
    path: "about-us",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/aboutus/aboutus.module"
      ).then((m) => m.AboutusModule)
  },
  {
    path: "offers",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/offer/offer.module"
      ).then((m) => m.OfferModule)
  },
  {
    path: "become-a-tutor",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/become-a-tutor/become-a-tutor.module"
      ).then((m) => m.BecomeATutorModule)
  },
  {
    path: "contact",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/contact/contact.module"
      ).then((m) => m.ContactModule)
  },
  {
    path: "institution/course/:course",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/course/course.module"
      ).then((m) => m.CourseModule)
  },
  {
    path: "imprint",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/imprint/imprint.module"
      ).then((m) => m.ImprintModule)
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/privacy-policy/privacy-policy.module"
      ).then((m) => m.PrivacyPolicyModule)
  },
  {
    path: "not-authenticated",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/not-authenticated/not-authenticated.module"
      ).then((m) => m.NotAuthenticatedModule)
  },
  {
    path: "not-verified",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/not-verified/not-verified.module"
      ).then((m) => m.NotVerifiedModule)
  },
  {
    path: "verify",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/verify/verify.module"
      ).then((m) => m.VerifyModule)
  },
  {
    path: "transition",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/spinnerComponent/spinner.module"
      ).then((m) => m.SpinnerModule)
  },
  {
    path: "forbidden",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/forbidden/forbidden.module"
      ).then((m) => m.ForbiddenModule)
  },
  {
    path: "home/:action",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/landing-page-switch/landingPageSwitch.module"
      ).then((m) => m.LandingPageSwitchModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: "institution/:institution",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/courses/courses.module"
      ).then((m) => m.CoursesModule)
  }
];

export const studyprimeRoutes = [
  {
    path: "offers",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/offer/offer.module"
      ).then((m) => m.OfferModule)
  },

  {
    path: "institution/:institution",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/courses/courses.module"
      ).then((m) => m.CoursesModule)
  },
  {
    path: "state/:institution",
    redirectTo: "institution/:institution"
  },

  {
    path: "institution/course/:course",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/course/course.module"
      ).then((m) => m.CourseModule)
  },
  {
    path: "state/course/:course",
    redirectTo: "institution/course/:course"
  },

  {
    path: "contact",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/contact/contact.module"
      ).then((m) => m.ContactModule)
  },
  {
    path: "imprint",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/imprint/imprint.module"
      ).then((m) => m.ImprintModule)
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/privacy-policy/privacy-policy.module"
      ).then((m) => m.PrivacyPolicyModule)
  },
  {
    path: "not-authenticated",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/not-authenticated/not-authenticated.module"
      ).then((m) => m.NotAuthenticatedModule)
  },
  {
    path: "not-verified",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/not-verified/not-verified.module"
      ).then((m) => m.NotVerifiedModule)
  },
  {
    path: "verify",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/verify/verify.module"
      ).then((m) => m.VerifyModule)
  },
  {
    path: "transition",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/spinnerComponent/spinner.module"
      ).then((m) => m.SpinnerModule)
  },
  {
    path: "forbidden",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/studyprime/additional-routes/forbidden/forbidden.module"
      ).then((m) => m.ForbiddenModule)
  },
  {
    path: "home/:action",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/landing-page-switch/landingPageSwitch.module"
      ).then((m) => m.LandingPageSwitchModule),
    canActivate: [LoggedInGuard]
  }
];

export const helperRoutes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  }
];

export const adminRoutes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin-access-modules/admin.module").then(
        (m) => m.AdminModule
      ),
    canActivate: [AdminGuardService]
  }
];

export const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/landing-page-switch/landingPageSwitch.module"
      ).then((m) => m.LandingPageSwitchModule)
  }
];
