import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from "@angular/core";
import { ContextService } from "../services/context.service";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";
import { environment } from "../../environments/environment";
import { IGlobalUIContext } from "../helper/IGlobalUIContext";
import { ITopbarNavigation } from "../helper/ITopbarNavigation";
import { TopbarContextService } from "../services/topbarNavigation.service";
import { SidebarService } from "../services/sidebar.service";
import { ISidebarConfig } from "../helper/ISidebarConfig";
import { Router } from "@angular/router";
import {
  adminRoutes,
  appRoutes,
  ecorepsRoutes,
  memberRoutes,
  studyprimeRoutes
} from "../app.routes";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  public context: Observable<IGlobalUIContext>;
  public topbarContext: Observable<ITopbarNavigation>;
  public sidebarContext: Observable<ISidebarConfig>;
  public landingPageUrl: string = environment.landingPageUrl;
  public updateTitle: (title: string) => void;
  public environment = environment;

  public get dontwaste(): string {
    return this.loginService.currentIsAdmin ? "" : "dontwaste";
  }

  constructor(
    private contextService: ContextService,
    private topbarContextService: TopbarContextService,
    private sidebarContextService: SidebarService,
    public loginService: LoginService,
    private router: Router
  ) {
    const newRoutes = [...appRoutes, ...memberRoutes, ...adminRoutes];

    if (environment.isEcoreps()) {
      router.resetConfig([].concat(newRoutes, ecorepsRoutes));
    } else {
      router.resetConfig([].concat(newRoutes, studyprimeRoutes));
    }

    this.updateTitle = function (title) {
      this.context.coverTitle = title;
    };

    this.context = this.contextService.context;
    this.topbarContext = this.topbarContextService.context;
    this.sidebarContext = this.sidebarContextService.context;

    if (environment.isStudyprime()) {
      this.topbarContextService.next({
        show: false,
        logo: "",
        showFreeTrial: true
      });
    } else if (environment.isEcoreps()) {
      this.topbarContextService.next({
        show: false,
        logo: "",
        showFreeTrial: false
      });
    }

    jQuery(function () {
      setTimeout(function () {
        // and here we add the onscroll listener. Yep, kill me pls
        jQuery(".math_block").on("scroll", function (e) {
          // so, we will add a cheat class, that doesn't really change the element, but forces the background to redraw. Afterwards we remove the class a-sync again, so it actually does redraw and it works more than once. Chrome needs a css change in order to redraw
          const el = jQuery(e.target);
          el.toggleClass("scroll-shadow-cheat-kill-me-later-for-this");
        });
      }, 3000); // just fucking kill me
    });

    // todo: Move code above to this global function and add the onscroll listener in the html onscroll="math_block_scroll_cheat(this)". Like this we can remove that stupid timeout
    (<any>window).math_block_scroll_cheat = function () {};
  }
}
