import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BrowserService {
  public isSafari = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();

    return (
      userAgent.indexOf("safari") !== -1 && !(userAgent.indexOf("chrome") > -1)
    );
  };
}
