/**
 * Created by huck on 16.06.18.
 */

import { Injectable, OnDestroy } from "@angular/core";
import {
  BehaviorSubject,
  empty,
  Observable,
  of,
  ReplaySubject,
  Subject
} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  withLatestFrom
} from "rxjs/operators";
import {
  ChapterIndex,
  findChapterByIdInChapters,
  findChapterContainingDisplay,
  flatten,
  hasSections
} from "ecoreps_shared/utils/chapterUtils";
import { ChapterDisplayMode, IChapter } from "ecoreps_shared/model/chapter";
import ICourse, { IColumn } from "ecoreps_shared/model/course";
import { ChapterIdPipe } from "../pipe/idformat";
import { ActivatedRoute, Router } from "@angular/router";
import { CourseService } from "./course.service";
import DisposeBag from "ecoreps_shared/utils/DisposeBag";
import { LoginService } from "./login.service";
import { tap } from "rxjs/internal/operators";

export interface IChapterRoute {
  _id: string;
  fragment: string;
}

export enum CourseParams {
  column = "columnId",
  subcontentId = "subcontentId",
  contentId = "contentId",
  courseId = "courseId"
}

interface ChapterOperator {
  route: ActivatedRoute;
  scroller: Observable<string>;
}

export interface ICourseRoute {
  column?: IColumn;
  fragment?: string;
  contentId: string;
  courseId: string;
  course: ICourse;
  admin?: boolean;
  pageType: PageType;
}

export enum PageType {
  content,
  progress
}

export class CourseRoute implements ICourseRoute {
  admin?: boolean;
  contentId: string;
  column?: IColumn;
  course: ICourse;
  courseId: string;
  fragment?: string;
  pageType: PageType;

  private _chapter: IChapter = null;

  public get chapterPool(): IChapter[] {
    return this.column ? this.column.chapters : [];
  }

  public get chapter(): IChapter {
    const chapter =
      this._chapter ||
      findChapterByIdInChapters(this.contentId, this.chapterPool);
    this._chapter = chapter;
    return this._chapter;
  }

  constructor(value: ICourseRoute) {
    this.admin = value.admin;
    this.contentId = value.contentId;
    this.course = value.course;
    this.courseId = value.courseId;
    this.column = value.column || null;
    this.fragment = value.fragment;
    this.pageType = value.pageType || PageType.content;
  }
}

@Injectable({
  providedIn: "root"
})
export class CourseNavigationContext implements OnDestroy {
  private _bag = new DisposeBag();

  public route: Observable<CourseRoute> = null;

  private _route = new BehaviorSubject<CourseRoute>(null);

  private _setupBag = new DisposeBag();

  private _chapterOperator = new ReplaySubject<ChapterOperator>(1);

  public isAdminRoute = new BehaviorSubject<boolean>(null);
  public currentExpanded = new BehaviorSubject<ChapterIndex>(null);

  public get currentCoursePreviewMode(): boolean {
    return (
      this.currentCourse &&
      this.currentCourse.hasPreview &&
      !this.loginService.hasPurchasedCourse(this.currentCourse._id.toString())
    );
  }

  //Routing: input

  public goToChapter = (
    chapter: IChapter | string,
    column: IColumn = null
  ): void => {
    const _chapter: IChapter =
      typeof chapter == "string"
        ? flatten(column.chapters).find((c) => c.url_slug == chapter)
        : chapter;
    const _column = column || this._route.value.column;
    if (_chapter) {
      this.loginService.storePreferenceForImmediateKey(
        "latest-" + this.currentCourse.url_slug + "-" + _column.url_slug,
        JSON.stringify({
          url_slug: _chapter.url_slug,
          title: _chapter.title
        })
      );
    }
    if (this.isAdminRoute.value) {
      this.navigateTo({
        ...(this._route.value as ICourseRoute),
        contentId: _chapter.url_slug,
        column: _column,
        fragment: null
      });
      return;
    }

    this._bag.add(
      this.resolveRoute(_chapter, column)
        .pipe(take(1))
        .subscribe((route) => {
          this.router.navigate(
            [
              this.isAdminRoute.value ? "admin" : null,
              "courses/course",
              this._route.value.courseId,
              _column.url_slug,
              route._id
            ].filter(Boolean),
            { fragment: route.fragment }
          );
        })
    );
  };

  public reloadCourse(
    contentId: string = undefined,
    reloadSame: boolean = false
  ): Observable<any> {
    const sub = new Subject<ICourse>();
    this._bag.add(
      this.courseService
        .getCourseById(this._route.value.courseId, true)
        .pipe(
          take(1),
          map((course) => {
            const route = new CourseRoute({
              ...this._route.value,
              course: course,
              courseId: course.url_slug
            } as ICourseRoute);
            if (contentId) {
              route.contentId = contentId;
            }
            return route;
          }),
          tap(() => {
            sub.next(null);
          })
        )
        .subscribe((route) => {
          this.navigateTo(route, reloadSame);
        })
    );
    return sub.asObservable().pipe(take(1));
  }

  public changeColumn = (
    column: IColumn | string,
    currentPageType = PageType.content
  ): void => {
    let col: IColumn;
    if (typeof column === "string") {
      col = this.currentCourse.columns.find((c) => c.url_slug == column);
    } else {
      col = column;
    }

    this.navigateTo(
      {
        ...(this._route.value as ICourseRoute),
        pageType: !currentPageType ? PageType.content : PageType.progress,
        column: col,
        contentId: null
      },
      false
    );
  };

  private navigateTo = (
    route: ICourseRoute,
    reloadSame: boolean = false
  ): void => {
    if (reloadSame) {
      location.reload();
      return;
    }

    const pageRoutes = {};
    pageRoutes[PageType.progress] = "courses/learningoverview";
    pageRoutes[PageType.content] = this.isAdminRoute.value
      ? "admin/course"
      : "courses/course";

    const extra = route.fragment ? {} : { fragment: route.fragment };

    this.router.navigate(
      [
        pageRoutes[route.pageType],
        route.courseId,
        route.column ? route.column.url_slug : null,
        route.contentId
      ].filter(Boolean),
      { ...extra }
    );
  };

  public get currentCourse(): ICourse {
    if (!this._route.value) {
      return null;
    }
    return this._route.value.course;
  }

  public get currentColumn(): Partial<IColumn> {
    if (!this._route.value) {
      return null;
    }
    return this._route.value.column;
  }

  public get currentChapter(): Partial<IChapter> {
    if (!this._route.value) {
      return null;
    }
    return this._route.value.chapter;
  }

  public get currentPool() {
    if (!this._route.value) {
      return null;
    }
    return this._route.value.chapterPool;
  }

  // Routing: Linking

  public expand(index: ChapterIndex): void {
    this.currentExpanded.next(index);
  }

  private subChapterIDs = (chapter: IChapter): string[] => {
    let result: string[] = [];
    if (chapter._id != null && chapter._id.length > 0) {
      result.push(chapter._id);
    }
    if (chapter.subchapters == null) {
      return result;
    }
    for (const sub of chapter.subchapters) {
      if (result.indexOf(sub._id) == -1) {
        const subresults = this.subChapterIDs(sub);
        result = result.concat(subresults);
      }
    }
    return result;
  };

  public resolveRoute = (
    target: IChapter,
    column: IColumn = undefined
  ): Observable<IChapterRoute> => {
    if (!target) {
      return empty();
    }

    function resolver(chapters: IChapter[]): IChapterRoute {
      let route_chapter: IChapter = null;
      let route_fragment = "chapter-title";
      if (
        target.displayMode === ChapterDisplayMode.includeChildren ||
        target.displayMode == ChapterDisplayMode.hierachical
      ) {
        if (
          !hasSections(target) &&
          target.subchapters.length > 0 &&
          target.displayMode == ChapterDisplayMode.hierachical
        ) {
          route_chapter = target.subchapters[0];
        } else {
          route_chapter = target;
        }
        //   route_fragment = new ChapterIdPipe().transform(route_chapter.prefix);
      } else {
        if (target.displayMode == ChapterDisplayMode.includedInParent) {
          const parent = findChapterContainingDisplay(target, chapters);
          route_chapter = parent;
          route_fragment = new ChapterIdPipe().transform(
            target.index.description
          );
        }
      }
      const result = {
        _id: route_chapter.url_slug,
        fragment: route_fragment,
        column: column
      };
      return result;
    }

    return this.route.pipe(
      shareReplay(1),
      map((route) => {
        return route.chapterPool;
      }),
      map(resolver)
    );
  };

  public scrolledToSubChapterId = (chapterId: string): void => {
    this._route.next(
      new CourseRoute({
        ...(this._route.value as ICourseRoute),
        fragment: chapterId
      })
    );
  };

  constructor(
    private courseService: CourseService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.subscribeRoutes();
  }

  setup(operator: ChapterOperator): void {
    this._chapterOperator.next(operator);
  }

  private subscribeRoutes() {
    this._setupBag.dispose();

    const routeObservable = this._chapterOperator.pipe(
      map((o) => o.route),
      distinctUntilChanged()
    );

    const isAdminObservable: Observable<boolean> = routeObservable.pipe(
      switchMap((route) => {
        if (route.parent) {
          return route.parent.url.pipe(
            map((components) => {
              return components[0].path == "admin";
            })
          );
        } else {
          return of(false);
        }
      })
    );

    const urlBasedRouteInformation = routeObservable
      .pipe(
        switchMap((route) => {
          return route.paramMap.pipe(
            withLatestFrom(route.parent.paramMap),
            withLatestFrom(route.url)
          );
        }),
        map(([[paramMap, parentMap], url]) => {
          let pageType: PageType;

          switch (url[0].path) {
            case "learningoverview":
              pageType = PageType.progress;
              break;
            default:
              pageType = PageType.content;
              break;
          }

          // @chris, above code does not seem to work r/n because the first url part is always the course
          // maybe you will some when find the problem, but for now this more dirty way works
          if (window.location.pathname.includes("/learningoverview/")) {
            pageType = PageType.progress;
          } else {
            pageType = PageType.content;
          }

          return {
            contentId: paramMap.get(CourseParams.contentId) || "",
            courseId:
              paramMap.get(CourseParams.courseId) ||
              parentMap.get(CourseParams.contentId),
            column: paramMap.get(CourseParams.column),
            pageType: pageType
          };
        })
      )
      .pipe(share());

    this._setupBag.addSubscriptions([
      urlBasedRouteInformation
        .pipe(
          switchMap((urlBasedInfo) => {
            return this.courseService.getCourseById(urlBasedInfo.courseId).pipe(
              map((course) => {
                const column = course.columns.find((c) => {
                  const isCol = urlBasedInfo.column
                    ? c.url_slug.toString() == urlBasedInfo.column.toString()
                    : false;

                  return isCol;
                });

                return new CourseRoute({
                  ...urlBasedInfo,
                  course: course,
                  column: column
                });
              })
            );
          })
        )
        .subscribe(this._route),
      //Are we an admin route?
      isAdminObservable.subscribe(this.isAdminRoute)
    ]);

    this.route = this._route.asObservable().pipe(
      filter(Boolean),
      distinctUntilChanged((prev: CourseRoute, curr: CourseRoute) => {
        //doesnt work if both are false
        return (
          prev.course &&
          curr.course &&
          prev.course.url_slug == curr.course.url_slug &&
          prev.contentId == curr.contentId &&
          prev.column &&
          curr.column &&
          prev.column.url_slug == curr.column.url_slug &&
          prev.fragment &&
          curr.fragment &&
          prev.fragment == curr.fragment
        );
      }),
      tap(() => {}),
      shareReplay(1)
    );
  }

  ngOnDestroy(): void {
    this._setupBag.dispose();
    this._bag.dispose();
  }
}
