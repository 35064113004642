import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, merge } from "rxjs";
import { map, switchMap, shareReplay } from "rxjs/operators";
import { ITopbarNavigation } from "../helper/ITopbarNavigation";

@Injectable({
  providedIn: "root"
})
export class TopbarContextService {
  private contextObservables = new BehaviorSubject<
    Observable<ITopbarNavigation>[]
  >([]);

  public context: Observable<ITopbarNavigation>;

  public next(context: ITopbarNavigation): void {
    this.contextObservables.next(
      this.contextObservables.value.concat([of(context)])
    );
  }

  constructor() {
    this.context = this.contextObservables.pipe(
      switchMap((observables) => merge(...observables)),
      map((val) => {
        return val;
      }),
      shareReplay(1)
    );
  }
}
