import ILandingPageContent from "../../models/landing-page/lp-content.model";

export const landingPageContent: ILandingPageContent = {
  welcomeContent: {
    headingPart1: "Intensiver Mathe-",
    headingPart2: "Abi Onlinekurs",
    headingAccent: "2022",
    preHeading: "Erfolgreich durch dein Abitur",
    selectPlaceholder: "Wähle dein Bundesland"
  },
  introContent: {
    heading: "Warum studyprime Online-Kurse?",
    description:
      "Wir von studyprime strukturieren deinen ganzen Abi-Stoff, erklären ihn verständlich und\n" +
      "      betreuen dich beim Lernen intensiv über Whatsapp.",
    columnHeading1: "Übersichtliche Struktur",
    columnDescription1:
      "Deinen ganzen Stoff haben wir übersichtlich strukturiert und einfach und verständlich erklärt.",
    columnHeading2: "Whatsapp-Service",
    columnDescription2:
      "Wir betreuen dich die ganze Zeit über Whatsapp, bis du dein Abitur schreibst.",
    columnHeading3: "Aktion: 2022 kostenlos",
    columnDescription3:
      "Für das Abi 2022 sind alle unsere Kurse inklusive WhatsApp-Service komplett kostenlos."
  },
  faqs: [
    {
      title: "Warum sind die Kurse dieses Jahr kostenlos?",
      text: "Weil die Schüler*innen aufgrund der Corona-Pandemie eine teilweise sehr ungünstige Lernsituation an den Schulen erlebt haben, haben wir beschlossen dieses Jahr alle Kurse kostenlos anzubieten."
    },
    {
      title: "Sind auch die kostenlosen Kurse inkl. WhatsApp-Service?",
      text: "Ja, wir führen unsere Online-Kurse mit vollem Umfang durch, obwohl sie kostenlos sind. Wir sehen die Betreuung über WhatsApp als einen sehr wichtigen Teil unserer Kurse an."
    },
    {
      title: "An den Kursen stehen Preise, sind sie wirklich kostenlos?",
      text: "Ja sie sind komplett kostenlos! Gib einfach bei der Buchung den folgenden Gutscheincode ein: „Studyprime2022“, dann reduziert sich der Preis auf 0€."
    },
    {
      title:
        "In den Kursen sind nur die Lösungen zu den alten Abi-Klausuren, wo finde ich die Aufgaben?",
      text: "Schreib uns einfach eine Nachricht auf WhatsApp, dann können wir dir sagen wo du die Aufgaben findest."
    },
    {
      title: "Was bringt es mir, wenn ich mich registriere?",
      text: "Die Kommentarfunktion und der Lernfortschritt sind nur für registrierte Benutzer verfügbar. Die Registrierung ist natürlich kostenlos."
    },
    {
      title: "Sind die Kurse  auch noch für das Abi 2023 kostenlos?",
      text: "Nein, die Aktion gilt nur für das Jahr 2022. Ab Januar 2023 werden die Kurse pro Fach 29€ kosten."
    },
    {
      title: "An wen kann ich mich wenden, wenn ich etwas nicht verstehe?",
      text: "In jedem Kurs steht eine Nummer, über die du die zuständige Ansprechpartner*in für das jeweilige Fach auf WhatsApp erreichst :)"
    },
    {
      title: "Wie schnell kann ich auf WhatsApp mit Hilfe rechnen?",
      text: "Wir bemühen uns, euch möglichst schnell zu helfen. Dabei achten wir darauf, dass wir euch umso schneller helfen, umso mehr wir uns dem Abitur-Termin nähern."
    },
    {
      title:
        "Mein Bundesland ist noch nicht dabei, kann ich auch ein anderes nutzen?",
      text: "Wir haben unsere Inhalte an die Bundesländer angepasst, deshalb kann es sein, dass gewisse Themen einen anderen Fokus haben. Die Grundlagen sind aber immer die gleichen, deshalb wird dir der Kurs für ein anderes Bundesland zwar nicht perfekt weiterhelfen, aber trotzdem sehr nützlich sein."
    },
    {
      title:
        "Was bedeutet Bundeslandspezifisch, an was habt ihr euch orientiert?",
      text: "Wir haben jeweils echte Abi Altklausuren durchgearbeitet, sowie offizielle Beispielsaufgaben der jeweiligen Kultusministerien. Daran haben wir dann unser Material orientiert."
    }
  ],
  teamInfo: {
    title: "Das sind Wir",
    part1:
      "Wir sind Edwin und Leo und haben 2013 während unseres Studiums ein Nachilfeunternehmen gegründet, mit dem wir\n" +
      "Student*innen auf ihre Mathe- und Statistik-Klausuren vorbereiten. Mittlerweile betreuen wir pro Studienjahr\n" +
      "mehr als 2000 Student*innen.",
    part2:
      "Seit 2021 bieten wir auch Online-Kurse für Schüler*innen an und helfen ihnen mit strukturiertem Material und\n" +
      "intensiver Betreuung bei der Vorbereitung auf das Mathe-Abitur."
  },
  whoElse: {
    titlePart1: "Wer macht sonst",
    titlePart2: "noch so mit",
    checks: [
      "Junge Menschen",
      "Mathe Lehramtsstudenten*innen",
      "aktive Mathelehrer*innen"
    ],
    star: "Mathe leicht verständlich"
  },
  reviews: {
    title: "Das sagen unsere Schüler"
  },
  attributes: [
    {
      icon: "tap",
      title: "Bundeslandspezifisch",
      description:
        "Die Inhalte unserer Kurse sind genau an dein Bundesland angepasst."
    },
    {
      icon: "book",
      title: "Material",
      description:
        "Unsere Kurse enthalten eine ausführliche Zusammenfassung (300+ Seiten) und ausführliche Lösungen zu echten\n" +
        "          Abi-Altklausuren."
    },
    {
      icon: "video",
      title: "Videoerklärungen",
      description:
        "Das gesamte schriftliche Material erklären wir zusätzlich in kurzen Videos."
    },
    {
      icon: "bookmark",
      title: "Taschenrechner",
      description:
        "Unser Material ist an euren jeweiligen Taschenrechner angepasst."
    },
    {
      icon: "cycle",
      title: "LK und GK",
      description:
        "Wenn es in deinem Bundesland Grund- und Leistungskurse gibt, haben wir unsere Inhalte daran angepasst."
    },
    {
      icon: "heart",
      title: "leicht verständliche Erklärungen",
      description:
        "Wir haben darauf geachtet, dass wir alle Themen in leicht verständlicher Sprache erklären."
    }
  ]
};
