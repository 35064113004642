import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, share, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import ICourse, { IUpdateCourseModel } from "ecoreps_shared/model/course";
import IUniversity from "ecoreps_shared/model/university";
import {
  ChapterPriority,
  IChapter,
  ICreateChapterModel,
  IMoveChapterModel,
  IUpdateChapterModel
} from "ecoreps_shared/model/chapter";
import {
  applyHierarchical,
  assignFullNames,
  ChapterIndex
} from "ecoreps_shared/utils/chapterUtils";
import ISection from "ecoreps_shared/model/section";
import * as moment from "moment";
import { IUserAccessState } from "ecoreps_shared/model/UserAccessState";
import IProfile, {
  ICompletionRequest,
  IProgressChapterEntry,
  ProgressState
} from "ecoreps_shared/model/profile";
import { LoginService } from "./login.service";
import { ISummaryRequest } from "../../../../shared/model/order";

@Injectable({
  providedIn: "root"
})
export class CourseService {
  cachedCourseData: { [key: string]: ICourse } = {};

  cachedChapterData: { [key: string]: IChapter } = {};

  private clearCourseCache() {
    this.cachedCourseData = {};
  }

  public bookmarkCourse = (courseId: string): Observable<IProfile> => {
    return this.client.post<IProfile>(
      [environment.profileUrl, "courses", courseId].join("/"),
      null
    );
  };

  public removeBookmark = (courseId: string): Observable<IProfile> => {
    return this.client.delete<IProfile>(
      [environment.profileUrl, "courses", courseId].join("/")
    );
  };

  public bookmarkedCourses = (): Observable<ICourse[]> => {
    return this.client.get<ICourse[]>(
      [environment.profileUrl, "courses"].join("/")
    );
  };

  public checkoutState = (body: ISummaryRequest): Observable<any> => {
    return this.client.post<any>(
      [environment.orderUrl, "summary/"].join("/"),
      body
    );
  };

  public completeChapter = (
    state: ProgressState,
    course: ICourse,
    chapterId: string,
    sectionId?: string
  ): Observable<IProgressChapterEntry> => {
    const req: ICompletionRequest = {
      sectionId,
      chapterId,
      state
    };
    return this.client.post<IProgressChapterEntry>(
      [environment.profileUrl, "progress", course.url_slug].join("/"),
      req
    );
  };

  public checkCompleted = (
    course: ICourse,
    chapterId: string
  ): Observable<IProgressChapterEntry> => {
    //alert([environment.apiUrl, "profile","progress", course.url_slug, chapterId].filter(Boolean).join("/"));
    return this.client.get<IProgressChapterEntry>(
      [environment.profileUrl, "progress", course.url_slug, chapterId]
        .filter(Boolean)
        .join("/")
    );
  };

  public progressSummary = (course: ICourse = null): Observable<any> => {
    return this.client.get<IProgressChapterEntry>(
      [environment.profileUrl, "progress", course ? course.url_slug : null]
        .filter(Boolean)
        .join("/")
    );
  };

  public resetProgress = (
    course: ICourse,
    column: string
  ): Observable<IProgressChapterEntry> => {
    return this.client.delete<IProgressChapterEntry>(
      [environment.profileUrl, "progress", course.url_slug, column]
        .filter(Boolean)
        .join("/")
    );
  };

  public getChapterById = (
    chapterId: string,
    forceReload: boolean = false
  ): Observable<IChapter> => {
    const date = moment().format("ddd, DD MMM YYYY HH:mm:ss ZZ") + " GMT";
    if (this.cachedChapterData[chapterId] && !forceReload) {
      return of(this.cachedChapterData[chapterId]);
    } else {
      return this.client
        .get<IChapter>([environment.apiUrl, "chapters", chapterId].join("/"), {
          headers: {
            //"If-Modified-Since": date
          }
        })
        .pipe(
          map((chapter) => {
            chapter.index = new ChapterIndex(
              chapter.orderString.split(",").map((i) => parseInt(i))
            );

            applyHierarchical(chapter.subchapters, (_, chap) => {
              chap.index = new ChapterIndex(
                chap.orderString.split(",").map((i) => parseInt(i))
              );
            });
            return chapter;
          }),
          map((chapter) => {
            this.cachedChapterData[chapterId] = chapter;
            return this.cachedChapterData[chapterId];
          })
        )
        .pipe(share());
    }
  };

  public getAccessListForCourse = (
    courseId: string
  ): Observable<IUserAccessState[]> => {
    return this.client
      .get<IUserAccessState[]>(
        [environment.profileUrl, "courses", "access", courseId.toString()].join(
          "/"
        )
      )
      .pipe(
        map((list) => {
          return list;
        })
      )
      .pipe(share());
  };

  public removeAllBookmarksFromCourse(course: ICourse) {
    return this.client
      .delete<IUserAccessState[]>(
        [
          environment.profileUrl,
          "courses",
          "bookmarks",
          course._id.toString()
        ].join("/")
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public revokeAccessForEveryoneInCourse(course: ICourse) {
    return this.client
      .delete<{ updated: IUserAccessState[] }>(
        [
          environment.profileUrl,
          "courses",
          "access",
          course._id.toString()
        ].join("/")
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public applyPermissionChanges(
    states: IUserAccessState[],
    course: ICourse
  ): Observable<any> {
    return this.client
      .post<IUserAccessState[]>(
        [
          environment.profileUrl,
          "courses",
          "access",
          course._id.toString()
        ].join("/"),
        states
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public grantCourseToUserId(userId: string, course: ICourse): Observable<any> {
    return this.client
      .get<IUserAccessState[]>(
        [environment.apiUrl, "courses", course.url_slug, "grant", userId].join(
          "/"
        ),
        {
          headers: {
            //"If-Modified-Since": date
          }
        }
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public revokeCourseFromUserId(
    userId: string,
    course: ICourse
  ): Observable<any> {
    return this.client
      .get<IUserAccessState[]>(
        [environment.apiUrl, "courses", course.url_slug, "revoke", userId].join(
          "/"
        ),
        {
          headers: {
            //"If-Modified-Since": date
          }
        }
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public getCourseById = (
    courseId: string,
    forceReload: boolean = false
  ): Observable<ICourse> => {
    const date = moment().format("ddd, DD MMM YYYY HH:mm:ss ZZ") + " GMT";

    if (this.cachedCourseData[courseId] && !forceReload) {
      return of(this.cachedCourseData[courseId]);
    } else {
      return this.client
        .get<ICourse>([environment.apiUrl, "courses", courseId].join("/"), {
          headers: {
            //"If-Modified-Since": date
          }
        })
        .pipe(
          map((course) => {
            this.cachedCourseData[courseId] = null;
            for (const col of course.columns) {
              col.chapters = assignFullNames(
                col.chapters,
                null,
                course.columns
              );
            }
            this.cachedCourseData[courseId] = course;
            return this.cachedCourseData[courseId];
          })
        )
        .pipe(share());
    }
  };

  public duplicateChapter(
    chapter: IChapter,
    courseId: string
  ): Observable<IChapter> {
    const body = {
      _id: chapter._id
    };

    return this.client.post<IChapter>(
      [
        environment.apiUrl,
        "courses",
        courseId,
        "chapters",
        "duplicateChapter"
      ].join("/"),
      body
    );
  }

  public deleteChapter(chapter: IChapter, courseId: string): Observable<any> {
    return this.client.delete<any>(
      [environment.apiUrl, "courses", courseId, "chapters", chapter._id].join(
        "/"
      )
    );
  }

  public updateChapter(chapter: IChapter, courseId: string) {
    const body: IUpdateChapterModel = {
      _id: chapter._id,
      title: chapter.title,
      displayMode: chapter.displayMode,
      priority: chapter.priority || ChapterPriority.normal,
      previewMode: chapter.previewMode
    };
    return this.client.put(
      [environment.apiUrl, "courses", courseId, "chapters"].join("/"),
      body
    );
  }

  public moveChapter(
    chapter: string,
    courseId: string,
    moveModel: IMoveChapterModel
  ): Observable<ICourse> {
    const body = moveModel;
    const chap = body.chapterSlug;
    delete body.chapterSlug;
    return this.client.post<ICourse>(
      [environment.apiUrl, "courses", courseId, "chapters", chap, "move"].join(
        "/"
      ),
      body
    );
  }

  public updateCourse(course: ICourse) {
    const body: IUpdateCourseModel = {
      _id: course._id,
      title: course.title,
      contact_mail: course.contact_mail,
      contact_phone: course.contact_phone,
      coverURL: undefined
    };
    return this.client
      .put([environment.apiUrl, "courses"].join("/"), body)
      .pipe(
        tap((res) => {
          this.clearCourseCache();
        })
      );
  }

  public rebuildCourse(course: ICourse): Observable<any> {
    return this.client.get(
      [environment.apiUrl, "courses", course.url_slug, "rebuild"].join("/")
    );
  }

  public deleteCourse(course: ICourse): Observable<string> {
    return this.client.delete<any>(
      [environment.apiUrl, "courses", course._id].join("/")
    );
  }

  public addChapter(
    chapterCreationModel: ICreateChapterModel,
    course: ICourse
  ): Observable<IChapter> {
    const body = chapterCreationModel;

    const courseId = course._id;

    return this.client.post<IChapter>(
      [environment.apiUrl, "courses", courseId, "chapters"].join("/"),
      body
    );
  }

  public addChapterSection = (
    section: ISection,
    courseId: string,
    chapterId: string
  ): Observable<ISection> => {
    return this.client.post<ISection>(
      [
        environment.apiUrl,
        "courses",
        courseId,
        "chapters",
        chapterId,
        "sections"
      ].join("/"),
      section
    );
  };

  public updateChapterSection = (
    section: ISection,
    courseId: string,
    chapterId: string
  ): Observable<ISection> => {
    //update fields
    /*section.content = section.editorContent;
    section.editorContent = undefined;
    section._id = undefined;*/
    return this.client.put<ISection>(
      [
        environment.apiUrl,
        "courses",
        courseId,
        "chapters",
        chapterId,
        "sections"
      ].join("/"),
      section
    );
  };

  public deleteChapterSection = (
    sectionId: string,
    courseId: string,
    chapterId: string
  ): Observable<IUniversity> => {
    return this.client.delete<IUniversity>(
      [
        environment.apiUrl,
        "courses",
        courseId,
        "chapters",
        chapterId,
        "sections",
        sectionId
      ].join("/")
    );
  };

  //deprecated methods ( for old api)
  constructor(private client: HttpClient, private loginService: LoginService) {
    loginService.refreshTokens.subscribe(() => {
      this.clearCourseCache();
    });
  }
}
