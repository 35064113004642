import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SectionComponent } from "./component/section.component";
import { CommentsComponent } from "../../../../../components/comments/comments.component";
import { CommentComponent } from "app/components/comment/comment.component";
import { SectionVideoModule } from "./video/section-video.module";
import { RenderMathPipeModule } from "app/pipe/renderMath";
import { ProgressControlsModule } from "../../progress-controls/progress-controls.module";
import { AccordeonModule } from "../../../../public-access-modules/accordeon/accordeon.module";

@NgModule({
  declarations: [SectionComponent, CommentsComponent, CommentComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SectionVideoModule,
    RenderMathPipeModule,
    ProgressControlsModule,
    AccordeonModule
  ],
  exports: [SectionComponent, CommentsComponent, CommentComponent]
})
export class SectionModule {}
