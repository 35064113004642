import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import IUniversity from "ecoreps_shared/model/university";
import { pagesConfig } from "../modules/public-access-modules/shared/content/pages-content";

@Injectable({
  providedIn: "root"
})
export class TranslationService {
  // determines which translation to use. Is only used by this service itself to make my life easier
  private determine(ecoreps: string, studyprime: string): string | null {
    if (environment.isEcoreps()) {
      return ecoreps;
    } else if (environment.isStudyprime()) {
      return studyprime;
    }
    return null;
  }

  public environmentTitle(): string {
    return this.determine("ecoreps", "studyprime");
  }

  public getPageContent<T>(page: string): T {
    return pagesConfig[this.environmentTitle()][page];
  }

  // Plural of students title
  public studentsTitle(): string {
    return this.determine("Studierenden", "Schüler");
  }

  // Heading in public page courses
  public coursesTitle(): string {
    return this.determine("Klausurvorbereitungskurse", "Abi Onlinekurse");
  }

  // Subline in public page courses
  public coursesTitleSubline(institution: IUniversity): string {
    return this.determine(
      institution ? "An der " + institution.name : "",
      institution ? institution.name : ""
    );
  }

  // Contact E-Mail in public page contact
  public contactEmail(): string {
    return this.determine("kontakt@ecoreps.de", "kontakt@studyprime.de");
  }
  // Contact phone number in public page contact
  public contactPhone(): string {
    return this.determine("0175 335 31 58", "0151 568 794 82");
  }
  // Contact phone number URL formatted in public page contact
  public contactPhoneForURL(): string {
    return this.determine("+491753353158", "+4915156879482");
  }

  public orderEmail(): string {
    return this.determine("orders@ecoreps.de", "orders@studyprime.de");
  }

  // Singular title of institution
  public institutionSingular(): string {
    return this.determine("Universität", "Bundesland");
  }
}
